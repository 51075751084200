import React from 'react'
import FormilyButton, {  FormilyButtonProps } from './Button'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createNormalSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import cls from 'classnames'
import './styles.less'

export const Button: DnFC<FormilyButtonProps> = ({ clickEventProps = {}, mapParmsToProps, ...props}) => {
  return props.disabled ? (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-button-box"
    >
      <FormilyButton
        {...props}
        className={cls(props.className, "dn-button")}
      />
    </div>
  ) : (
    <FormilyButton
      {...props}
      className={cls(props.className, "dn-button")}
    />
  );
}


Button.Behavior = createBehavior(
  {
    name: 'Button',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Button',
    designerProps: {
      propsSchema: createNormalSchema(AllSchemas.Button),
      cloneable: true
    },
    designerLocales: AllLocales.Button,
  },
)

Button.Resource = createResource(
  {
    icon: 'ButtonSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'void',
          // 'x-decorator': 'FormItem',
          'x-component': 'Button',
          'x-component-props': {
            text: '按钮',
            size: 'middle',
            type: 'primary',
          }
        },
      },
    ],
  },
)
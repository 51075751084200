import React from 'react'
import { CheckList as AntdCheckList } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const CheckList: DnFC<React.ComponentProps<typeof AntdCheckList>> = AntdCheckList

CheckList.Behavior = createBehavior({
  name: 'CheckList',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'CheckList',
  designerProps: {
    propsSchema: createFieldSchema(AllSchemas.CheckList),
  },
  designerLocales: {
    'zh-CN': {
      title: '可勾选列表',
      settings: {
        'x-component-props': {
          multiple: '是否多选',
        },
      },
    }
  },
})

CheckList.Resource = createResource({
  icon: 'CheckListSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'boolean',
        title: 'CheckList',
        enum: [{label: '选项 1', value: 'check1'}, {label: '选项 2', value: 'check2'}],
        'x-decorator': 'FormItem',
        'x-component': 'CheckList',
      },
    },
  ],
})

import React from 'react'
import { Selector as FormilySelector } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const Selector: DnFC<React.ComponentProps<typeof FormilySelector>> =
  FormilySelector


Selector.Behavior = createBehavior(
  {
    name: 'Selector',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Selector',
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Selector),
    },
    designerLocales: AllLocales.Selector,
  },
)

Selector.Resource = createResource(
  {
    icon: 'SelectorSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'string',
          title: 'Selector',
          'x-decorator': 'FormItem',
          'x-component': 'Selector',
          enum: [
            {
              label: '选项1',
              value: 'key1'
            },
            {
              label: '选项2',
              value: 'key2'
            }
          ]
        },
      },
    ],
  },
)

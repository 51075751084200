import { connect, mapProps, mapReadPretty } from '@formily/react'
import React from 'react';
import { Radio as AntdRadio, Space } from 'antd-mobile'
import { uid } from '@formily/shared'
// import { PreviewText } from '../preview-text'

const RadioGroup = (props) => {
  const { options, ...otherProps } =  props;
  return <AntdRadio.Group {...otherProps} >
    <Space direction='vertical'>
      {
        options.map(item => {
          return <AntdRadio key={item.key || uid()} block {...item} >{item.label}</AntdRadio>
        })
      }
    </Space>
  </AntdRadio.Group>
}

export const InternalRadio = connect(
  AntdRadio,
  mapProps({
    value: 'checked',
    onInput: 'onChange',
  })
)

const Group = connect(
  RadioGroup,
  mapProps({
    dataSource: 'options',
  }),
  // mapReadPretty(PreviewText.Select)
)

export const Radio = Object.assign(InternalRadio, {
  __ANT_RADIO: true,
  Group,
})

export default Radio

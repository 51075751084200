import React from 'react'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createVoidFieldSchema, createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import FormilyProTable, { FormilyProTableProps } from './table'

export const ProTable: DnFC<FormilyProTableProps> = FormilyProTable

ProTable.Behavior = createBehavior(
  {
    name: 'ProTable',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'ProTable',
    designerProps: {
      propsSchema: createBaseSchema(AllSchemas.ProTable),
    },
    designerLocales: AllLocales.ProTable,
  },
)

ProTable.Resource = createResource(
  {
    icon: 'ShowTableSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'void',
          'x-component': 'ProTable',
          'x-component-props': {
            columns: [
              {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                textType: 'index'
              },
            ],
            dataSource: []
          }
        },
      },
    ],
  },
)
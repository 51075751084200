import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyTag, { FormilyTagProps } from "./Tag"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createNormalSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { Icon } from "../../setters";
import "./styles.less";

interface FormilyITagProps extends FormilyTagProps {
  iconName?: string;
  customColor?: string;
}

export const Tag: DnFC<FormilyITagProps> = ({
  iconName,
  customColor,
  title,
  ...props
}) => {
  if (iconName) {
    props.icon = <Icon name={iconName} style={{fontSize: '12px'}}/>
  }
  props.color = props.color || customColor;
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-tag"
    >
    
      <FormilyTag {...props} >{ title }</FormilyTag>
    </div>
  );
};

Tag.Behavior = createBehavior({
  name: "Tag",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Tag",
  designerProps: {
    propsSchema: createNormalSchema(AllSchemas.Tag),
  },
  designerLocales: AllLocales.Tag,
});

Tag.Resource = createResource({
  icon: "TagSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Tag",
        "x-component-props": {
          title: "标签",
          style: {
            margin: '4px 0px 4px 0px'
          }
        },
      },
    },
  ],
});